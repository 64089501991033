import {
  BrandSlack,
  Breakpoints,
  ButtonColor,
  ButtonSize,
  compact,
  type HeaderButton,
  HeaderEntryTextColor,
  IconGift,
  IconGraduateHat,
  IconInbox,
  IconQuestion,
  IconSettings,
  IconSignOut,
  IconSparkle,
  IconSwitch,
  IconWarningTriangle,
  type MobileNavEntry,
  type NavProps,
  NavTheme,
  PageHeader,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import React, { type ReactElement, useMemo } from 'react';

import {
  CoachStatus,
  type SkuTier,
} from '../__generated-gql-types__/globalTypes';
import { useAuth, useLogout } from '../context/AuthContext';
import { LexSlug, useLex } from '../context/LexContext';
import { useOnboardingStatuses } from '../context/OnboardingStatusesContext';
import { useOpportunities } from '../context/OpportunityContext';
import {
  APPLICANT_SITE_URL,
  APPROVED_COACH_STATUSES,
  BASE_RELEASE_NOTES_URL,
  COACH_COMMUNITY_SLACK_URL,
  COACH_LIBRARY_URL,
  ONBOARDED_COACH_STATUSES,
  SUPPORT_URL,
} from '../utils/constants';
import { LelandImpersonation } from '../utils/impersonation';
import { getTiersAtOrBelow } from '../utils/tier';

import {
  useMainNavigationPublicOpportunitiesQuery,
  useMainNavigationUserQuery,
} from './__generated-gql-types__/MainNavigationSidebar.generated';
import { IncompleteIndicator } from './common/IncompleteIndicator';
import { NotificationIcon } from './common/NotificationIcon';

interface NavLink {
  href: string;
  label: string;
  icon?: ReactElement;
  isExternal?: boolean;
}

interface NavbarProps {
  // hide navigation sidebar and profile menu navigation options
  hideInternalNavigation?: boolean;
  navLinks?: NavLink[];
}

const Navbar: React.FC<NavbarProps> = ({ hideInternalNavigation }) => {
  const { currentUser, isLoadingUser, isImpersonating } = useAuth();
  const { isLexEnabled } = useLex();
  const isSm = useMediaQuery(Breakpoints.SM);

  const isPublicOpportunitiesEnabled = isLexEnabled(
    LexSlug.PUBLIC_OPPORTUNITIES,
  );

  const isReferralProgramEnabled =
    isLexEnabled(LexSlug.FEATURE_PACKAGE_HOURLY_UNIFICATION) &&
    currentUser?.coach?.newExperience;

  const { statuses } = useOnboardingStatuses();
  const { logout, logoutLoading } = useLogout();

  const isApplicant = currentUser?.applicant != null;

  const { opportunities } = useOpportunities();

  const { data: userData } = useMainNavigationUserQuery();

  const coachTier = userData?.user?.coach?.tier;
  const tiersList: SkuTier[] = useMemo(
    () => (coachTier ? getTiersAtOrBelow(coachTier) : []),
    [coachTier],
  );

  const { data: opportunitiesData } = useMainNavigationPublicOpportunitiesQuery(
    {
      variables: {
        tiers: tiersList,
      },
      skip: tiersList.length === 0,
    },
  );

  const approvedCoach =
    currentUser?.coach?.status != null &&
    APPROVED_COACH_STATUSES.includes(currentUser?.coach?.status);

  const showSettingsIncompleteIndicator =
    currentUser?.coach?.status === CoachStatus.APPROVED &&
    !statuses?.stripeDone;

  const isOnboardedCoach =
    currentUser?.coach?.status != null &&
    ONBOARDED_COACH_STATUSES.includes(currentUser?.coach?.status);

  const isApprovedCoach =
    currentUser?.coach?.status != null &&
    (isOnboardedCoach ||
      APPROVED_COACH_STATUSES.includes(currentUser.coach.status));

  const isLiveCoach =
    currentUser?.coach?.status != null &&
    currentUser.coach.status === CoachStatus.LIVE;

  const IMPERSONATION_HEADER_ENTRY: HeaderButton = useMemo(
    () => ({
      label: 'EXIT IMPERSONATION',
      LeftIcon: IconWarningTriangle,
      textColor: HeaderEntryTextColor.RED,
      onClick: () => {
        LelandImpersonation.removeImpersonatedUserId();
        window.location.href =
          '/internal/ops/coaches?status=' + currentUser?.coach?.status;
      },
    }),
    [currentUser?.coach?.status],
  );

  const mobileSidebarLinks: MobileNavEntry[] = useMemo(() => {
    const approvedCoachOptions: MobileNavEntry[] = isApprovedCoach
      ? [
          {
            url: '/calendar/appointments/upcoming',
            label: 'Calendar',
            CustomRightIcon: showSettingsIncompleteIndicator
              ? ({ iconClassName }) => (
                  <IncompleteIndicator className={iconClassName} />
                )
              : undefined,
          },
          {
            label: 'Offerings',
            CustomRightIcon: showSettingsIncompleteIndicator
              ? ({ iconClassName }) => (
                  <IncompleteIndicator className={iconClassName} />
                )
              : undefined,
            childEntries: [
              ...(isLexEnabled(LexSlug.FEATURE_PACKAGE_HOURLY_UNIFICATION) &&
              currentUser.coach?.newExperience
                ? [
                    {
                      label: 'Pricing',
                      url: '/offerings/pricing',
                    },
                    {
                      label: 'Packages',
                      url: '/offerings/packages',
                    },
                  ]
                : [
                    {
                      label: 'Hourly',
                      url: '/offerings/hourly',
                    },
                    {
                      label: 'Packages',
                      url: '/offerings/packages/standardized',
                    },
                  ]),
              {
                label: 'Free events',
                url: '/offerings/events',
              },
              ...(!currentUser?.coach?.allowPaidClass
                ? []
                : [
                    {
                      label: 'Group classes',
                      url: '/offerings/classes',
                    },
                  ]),
              {
                label: 'Leland+',
                url: '/offerings/leland-plus',
              },
            ],
          },
          {
            url: '/earnings',
            label: 'Earnings',
            CustomRightIcon: showSettingsIncompleteIndicator
              ? ({ iconClassName }) => (
                  <IncompleteIndicator className={iconClassName} />
                )
              : undefined,
          },
        ]
      : [];
    const onboardedCoachOptions: MobileNavEntry[] = isOnboardedCoach
      ? [
          {
            label: 'Opportunities',
            ...(isPublicOpportunitiesEnabled
              ? {
                  childEntries: [
                    {
                      label: 'My opportunities',
                      url: '/opportunities',
                      CustomRightIcon: opportunities?.length
                        ? () => (
                            <NotificationIcon
                              isInbox={false}
                              icon=""
                              notificationCount={opportunities.length}
                            />
                          )
                        : undefined,
                    },
                    {
                      label: 'Public opportunities',
                      url: '/opportunities/public',
                      CustomRightIcon: opportunitiesData?.publicOpportunities
                        ?.total
                        ? () => (
                            <NotificationIcon
                              isInbox={false}
                              icon=""
                              notificationCount={
                                opportunitiesData?.publicOpportunities?.total
                              }
                            />
                          )
                        : undefined,
                    },
                  ],
                }
              : { url: '/opportunities' }),
          },
          {
            url: '/reviews',
            label: 'Reviews',
          },
          {
            url: '/discount-codes',
            label: 'Discount Codes',
          },
        ]
      : [];
    const opsOption: Nullable<MobileNavEntry> = currentUser?.isOps
      ? {
          url: '/internal/ops/coaches',
          label: 'Ops Tools',
        }
      : null;
    return (
      [
        isImpersonating ? IMPERSONATION_HEADER_ENTRY : null,
        {
          url: '/inbox',
          label: 'Inbox',
        },
        {
          url: '/profile/background',
          isExternal: false,
          label: 'Profile',
        },
        ...approvedCoachOptions,
        ...onboardedCoachOptions,
        opsOption,
      ] satisfies Array<Nullable<MobileNavEntry>>
    ).filter(compact);
  }, [
    IMPERSONATION_HEADER_ENTRY,
    currentUser?.coach?.allowPaidClass,
    currentUser?.coach?.newExperience,
    currentUser?.isOps,
    isApprovedCoach,
    isImpersonating,
    isLexEnabled,
    isOnboardedCoach,
    isPublicOpportunitiesEnabled,
    opportunities.length,
    opportunitiesData?.publicOpportunities?.total,
    showSettingsIncompleteIndicator,
  ]);

  const leftLinks = useMemo(() => {
    const links: NavProps['leftLinks'] = [];

    if (isImpersonating && currentUser && isSm) {
      links.push(IMPERSONATION_HEADER_ENTRY);
    }

    return links.length ? links : undefined;
  }, [IMPERSONATION_HEADER_ENTRY, currentUser, isImpersonating, isSm]);

  const profileMenuItemSections: MobileNavEntry[][] = useMemo(() => {
    const applicantOptions: MobileNavEntry[] =
      isApplicant || !hideInternalNavigation
        ? [
            {
              url: '/settings',
              LeftIcon: IconSettings,
              label: 'Settings',
            },
          ]
        : [];
    const approvedCoachProfileMenuOptions: MobileNavEntry[] = approvedCoach
      ? [
          {
            url: COACH_COMMUNITY_SLACK_URL,
            isExternal: true,
            LeftIcon: BrandSlack,
            label: 'Coach Community',
          },
          {
            url: COACH_LIBRARY_URL,
            LeftIcon: IconGraduateHat,
            label: "Coach's Corner",
          },
        ]
      : [];

    const switchToClientOption: Nullable<MobileNavEntry> =
      isApplicant || !hideInternalNavigation
        ? {
            url: APPLICANT_SITE_URL,
            LeftIcon: IconSwitch,
            label: 'Switch to Client view',
          }
        : null;

    const topSection: MobileNavEntry[] = applicantOptions;
    const sections: Array<Nullable<Array<Nullable<MobileNavEntry>>>> = [
      ...(isImpersonating ? [[IMPERSONATION_HEADER_ENTRY]] : []),
      topSection,
      [
        switchToClientOption,
        ...approvedCoachProfileMenuOptions,
        {
          url: SUPPORT_URL,
          isExternal: true,
          LeftIcon: IconQuestion,
          label: 'Help & Support',
        },
        {
          onClick: (e) => {
            e.preventDefault();
            void logout();
          },
          LeftIcon: IconSignOut,
          label: 'Log Out',
        },
      ],
    ];

    return sections.reduce<MobileNavEntry[][]>(
      (reducedSections, currentSection) => {
        const filteredCurrentSection = currentSection?.filter(compact);
        if (filteredCurrentSection?.length) {
          reducedSections.push(filteredCurrentSection);
        }
        return reducedSections;
      },
      [],
    );
  }, [
    IMPERSONATION_HEADER_ENTRY,
    approvedCoach,
    hideInternalNavigation,
    isApplicant,
    isImpersonating,
    logout,
  ]);

  const rightLinks: NavProps['rightLinks'] = useMemo(() => {
    const links: NavProps['rightLinks'] = [];
    if (currentUser && !isSm) {
      links.push({
        label: 'Inbox',
        LeftIcon: IconInbox,
        hideLabel: true,
        url: `/inbox`,
      });
    }
    if (isReferralProgramEnabled && currentUser && isSm && isLiveCoach) {
      links.push({
        label: 'Refer',
        LeftIcon: IconGift,
        url: '/referrals',
        buttonColor: ButtonColor.BLACK,
        size: ButtonSize.SMALL,
      });
    }
    return links;
  }, [currentUser, isSm, isReferralProgramEnabled, isLiveCoach]);

  const LulNavProps: NavProps = {
    theme: isImpersonating ? NavTheme.RED : undefined,
    mobileLinks: hideInternalNavigation
      ? undefined
      : {
          sidebar: mobileSidebarLinks,
          footer: [
            ...(isReferralProgramEnabled && isLiveCoach
              ? [
                  {
                    url: '/referrals',
                    LeftIcon: IconGift,
                    label: 'Refer',
                  },
                ]
              : []),
            {
              url: '/settings',
              LeftIcon: IconSettings,
              label: 'Settings',
            },
            {
              label: "What's New",
              url: BASE_RELEASE_NOTES_URL,
              isExternal: true,
              LeftIcon: IconSparkle,
            },
          ],
        },
    leftLinks,
    rightLinks,
    urls: {
      home: '/',
      login: `/login`,
      signup: `/signup`,
      inbox: `/inbox`,
    },
    user: currentUser
      ? {
          ...currentUser,
          profileLink: '/profile/background',
        }
      : undefined,
    profileMenuItemSections: profileMenuItemSections,
    userLoading: isLoadingUser || logoutLoading,
  };

  return <PageHeader sticky={true} navProps={LulNavProps} subNavProps={null} />;
};

export default Navbar;
