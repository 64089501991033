import { SkuTier } from '../__generated-gql-types__/globalTypes';

export const tierDescriptionMap: Record<SkuTier, string> = {
  [SkuTier.ELITE_PLUS]: 'Top 1% of coaches on Leland',
  [SkuTier.ELITE]: 'Professional coaches and industry leaders',
  [SkuTier.PRO]: 'Proven coaches with extensive experience',
  [SkuTier.BASE]: 'Vetted experts with practical knowledge',
};

export const getTiersAtOrBelow = (tier: SkuTier) => {
  const tiers: SkuTier[] = [];
  /* eslint-disable no-fallthrough -- we want fallthrough to collect all tiers */
  switch (tier) {
    case SkuTier.ELITE_PLUS:
      tiers.push(SkuTier.ELITE_PLUS);
    case SkuTier.ELITE:
      tiers.push(SkuTier.ELITE);
    case SkuTier.PRO:
      tiers.push(SkuTier.PRO);
    case SkuTier.BASE:
      tiers.push(SkuTier.BASE);
  }
  /* eslint-enable no-fallthrough */
  return tiers;
};
