import {
  IconCalendarAlt,
  IconChevronDown,
  IconChevronUp,
  IconDiscount,
  IconEarnings,
  IconLightning,
  IconLink,
  IconOnboarding,
  IconOpsTools,
  IconProfile,
  IconShare,
  IconSparkle,
  IconStarOutline,
  IconStore,
} from '@leland-dev/leland-ui-library';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { type ReactElement, useMemo, useState } from 'react';

import {
  CoachStatus,
  CoachVisibility,
  type SkuTier,
} from '../__generated-gql-types__/globalTypes';
import { useAuth } from '../context/AuthContext';
import { LexSlug, useLex } from '../context/LexContext';
import { useOnboardingStatuses } from '../context/OnboardingStatusesContext';
import { useOpportunities } from '../context/OpportunityContext';
import {
  APPROVED_COACH_STATUSES,
  BASE_RELEASE_NOTES_URL,
  ONBOARDED_COACH_STATUSES,
} from '../utils/constants';
import useCopyToClipboardWithToast from '../utils/hooks/useCopyToClipboardWithToast';
import { getTiersAtOrBelow } from '../utils/tier';
import { getCoachProfileUrl } from '../utils/url';

import {
  useMainNavigationPublicOpportunitiesQuery,
  useMainNavigationUserQuery,
} from './__generated-gql-types__/MainNavigationSidebar.generated';
import { IncompleteIndicator } from './common/IncompleteIndicator';
import NewIndicator from './common/NewIndicator';
import { NotificationIcon } from './common/NotificationIcon';
import SidebarAlert from './page/SidebarAlert';
import SidebarNotice from './page/SidebarNotice';

const NavbarMessageNotification = dynamic(
  () => import('./inbox/NavbarMessageNotification'),
  {
    ssr: false,
  },
);

const ICON_CLASSNAME = 'text-leland-gray-dark w-6 h-6';
const CATEGORIES_PAGE_PATHNAME = '/profile/categories';

interface MainNavigationSidebarProps {
  className?: string;
}

const MainNavigationSidebar: React.FC<MainNavigationSidebarProps> = ({
  className,
}) => {
  const { currentUser, isLoadingUser } = useAuth();
  const { statuses, areStatusesLoading } = useOnboardingStatuses();
  const { isLexEnabled } = useLex();

  const { data: userData } = useMainNavigationUserQuery();

  const coachTier = userData?.user?.coach?.tier;
  const tiersList: SkuTier[] = useMemo(
    () => (coachTier ? getTiersAtOrBelow(coachTier) : []),
    [coachTier],
  );

  const { data: opportunitiesData } = useMainNavigationPublicOpportunitiesQuery(
    {
      variables: {
        tiers: tiersList,
      },
      skip: tiersList.length === 0,
    },
  );

  const isOnboardedCoach =
    currentUser?.coach?.status != null &&
    ONBOARDED_COACH_STATUSES.includes(currentUser?.coach?.status);
  const isApprovedCoach =
    currentUser?.coach?.status != null &&
    (isOnboardedCoach ||
      APPROVED_COACH_STATUSES.includes(currentUser.coach.status));

  const coachIsUnlisted =
    !!currentUser?.coach &&
    currentUser.coach.visibility !== CoachVisibility.PUBLIC;

  const incompleteCategoriesExistForUnlisted =
    coachIsUnlisted && !areStatusesLoading && !statuses?.categoriesDone;

  const coachIsLive = currentUser?.coach?.status === CoachStatus.LIVE;
  const copyCoachLink = useCopyToClipboardWithToast();

  const { opportunities } = useOpportunities();

  const isPublicOpportunityEnabled = isLexEnabled(LexSlug.PUBLIC_OPPORTUNITIES);

  return (
    <div
      className={`flex min-w-55 max-w-55 border-r bg-white ${className ?? ''}`}
    >
      <div className="flex flex-col justify-between gap-4 overflow-y-auto p-2">
        <div className="grid gap-2">
          {isLoadingUser ? null : isOnboardedCoach ? (
            <SidebarLinkSection
              label="Inbox"
              mainHref="/inbox"
              openSectionIfMatchesPrefix="/inbox"
              icon={
                <NavbarMessageNotification iconClassName={ICON_CLASSNAME} />
              }
              isLoadingUser={isLoadingUser}
            />
          ) : (
            <SidebarLinkSection
              label="Onboarding"
              mainHref="/onboarding"
              openSectionIfMatchesPrefix="/onboarding"
              icon={<IconOnboarding className={ICON_CLASSNAME} />}
              isLoadingUser={isLoadingUser}
            />
          )}
          {isOnboardedCoach ? (
            <SidebarLinkSection
              label="Opportunities"
              mainHref="/opportunities"
              openSectionIfMatchesPrefix="/opportunities"
              icon={
                <NotificationIcon
                  icon={<IconLightning className={ICON_CLASSNAME} />}
                  notificationCount={
                    (opportunities?.length ?? 0) +
                    (opportunitiesData?.publicOpportunities?.total ?? 0)
                  }
                />
              }
              isLoadingUser={isLoadingUser}
              childEntries={
                isPublicOpportunityEnabled
                  ? [
                      {
                        label: 'My opportunities',
                        href: '/opportunities',
                        notificationCount: opportunities?.length ?? 0,
                      },
                      {
                        label: 'Public opportunities',
                        href: '/opportunities/public',
                        notificationCount:
                          opportunitiesData?.publicOpportunities?.total ?? 0,
                      },
                    ]
                  : undefined
              }
            />
          ) : null}
          <SidebarLinkSection
            label="Profile"
            mainHref="/profile/background"
            openSectionIfMatchesPrefix="/profile"
            icon={<IconProfile className={ICON_CLASSNAME} />}
            showIncompleteAlert={incompleteCategoriesExistForUnlisted}
            showOnboardingRequiredTooltip={
              !isOnboardedCoach &&
              (!statuses?.profileDone || !statuses?.categoriesDone)
            }
            isLoadingUser={isLoadingUser}
            childEntries={[
              {
                label: 'Background',
                href: '/profile/background',
              },
              {
                label: 'Categories',
                href: '/profile/categories',
              },
            ]}
          />
          {isApprovedCoach ? (
            <>
              <SidebarLinkSection
                label="Calendar"
                mainHref="/calendar/appointments/upcoming"
                openSectionIfMatchesPrefix="/calendar"
                showOnboardingRequiredTooltip={
                  !isOnboardedCoach && !statuses?.availabilityDone
                }
                icon={<IconCalendarAlt className={ICON_CLASSNAME} />}
                isLoadingUser={isLoadingUser}
              />
              <SidebarLinkSection
                label="Offerings"
                mainHref="/offerings/hourly"
                openSectionIfMatchesPrefix="/offerings"
                showOnboardingRequiredTooltip={
                  !isOnboardedCoach && !statuses?.pricingServicesDone
                }
                icon={<IconStore className={ICON_CLASSNAME} />}
                isLoadingUser={isLoadingUser}
                childEntries={[
                  ...(isLexEnabled(
                    LexSlug.FEATURE_PACKAGE_HOURLY_UNIFICATION,
                  ) && currentUser.coach?.newExperience
                    ? [
                        {
                          label: 'Pricing',
                          href: '/offerings/pricing',
                        },
                        {
                          label: 'Packages',
                          href: '/offerings/packages',
                        },
                      ]
                    : [
                        {
                          label: 'Hourly',
                          href: '/offerings/hourly',
                        },
                        {
                          label: 'Packages',
                          href: '/offerings/packages/standardized',
                        },
                      ]),
                  {
                    label: 'Free events',
                    href: '/offerings/events',
                  },
                  ...(!currentUser?.coach?.allowPaidClass
                    ? []
                    : [
                        {
                          label: 'Group classes',
                          href: '/offerings/classes',
                        },
                      ]),
                  {
                    label: 'Leland+',
                    href: '/offerings/leland-plus',
                  },
                ]}
              />
              <SidebarLinkSection
                label="Earnings"
                mainHref="/earnings"
                openSectionIfMatchesPrefix="/earnings"
                showOnboardingRequiredTooltip={
                  !isOnboardedCoach && !statuses?.stripeDone
                }
                icon={<IconEarnings className={ICON_CLASSNAME} />}
                isLoadingUser={isLoadingUser}
              />
              {isOnboardedCoach ? (
                <>
                  <SidebarLinkSection
                    label="Reviews"
                    mainHref="/reviews"
                    openSectionIfMatchesPrefix="/reviews"
                    icon={<IconStarOutline className={ICON_CLASSNAME} />}
                    isLoadingUser={isLoadingUser}
                  />
                  <SidebarLinkSection
                    label="Discount Codes"
                    mainHref="/discount-codes"
                    openSectionIfMatchesPrefix="/discount-codes"
                    icon={<IconDiscount className={ICON_CLASSNAME} />}
                    isLoadingUser={isLoadingUser}
                  />
                </>
              ) : null}
            </>
          ) : null}
          {currentUser?.isOps ? (
            <SidebarLinkSection
              label="Ops Tools"
              mainHref="/internal/ops/coaches"
              openSectionIfMatchesPrefix="/internal/ops"
              icon={<IconOpsTools className={ICON_CLASSNAME} />}
              isLoadingUser={isLoadingUser}
            />
          ) : null}
        </div>
        <div className="flex flex-col items-center">
          {incompleteCategoriesExistForUnlisted ? (
            <SidebarAlert
              primary
              text={
                <>
                  Your coaching profile has been unlisted. Update your
                  categories in order to relist your account.{' '}
                  <a
                    className="link underline"
                    href={BASE_RELEASE_NOTES_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Details
                  </a>
                </>
              }
              link={{ href: CATEGORIES_PAGE_PATHNAME, label: 'Update Now' }}
            />
          ) : coachIsUnlisted ? (
            <SidebarAlert
              {...(currentUser?.coach?.visibility ===
              CoachVisibility.PRIVATE_OPEN
                ? { primary: true }
                : { danger: true })}
              text={
                currentUser?.coach?.visibility === CoachVisibility.PRIVATE_OPEN
                  ? "Your profile is private. It won't show up in Leland search results."
                  : "You've marked that you're not taking on new clients. Your profile won't show up in Leland search results."
              }
              link={{
                href: '/settings/account-settings',
                label: 'Update',
              }}
            />
          ) : coachIsLive ? (
            <SidebarNotice
              text={
                <>
                  <p className="flex items-start font-medium">
                    <IconShare className="mr-1 mt-1 size-3.5" />
                    Share your profile
                  </p>
                  <p className="text-leland-gray-light">
                    Send your clients a link to your profile.
                  </p>
                </>
              }
              buttonLabel="Copy link"
              leftIcon={IconLink}
              onClick={() => {
                if (currentUser.coach?.slug) {
                  return copyCoachLink(
                    getCoachProfileUrl(currentUser.coach.slug, null),
                  );
                }
              }}
            />
          ) : null}
          <a
            className="mb-2 flex items-center justify-center rounded-lg bg-white p-2 text-leland-gray-extra-light hover:bg-leland-gray-hover hover:text-leland-gray-light"
            href={BASE_RELEASE_NOTES_URL}
            target="_blank"
            rel="noreferrer"
          >
            <IconSparkle className="size-4.5" />
            <p className="ml-1 text-sm font-medium">What&rsquo;s new?</p>
          </a>
        </div>
      </div>
    </div>
  );
};

interface SidebarLinkSectionProps {
  label: string;
  mainHref: string;
  openSectionIfMatchesPrefix: string;
  showIncompleteAlert?: boolean;
  showOnboardingRequiredTooltip?: boolean;
  showSubmitRequiredTooltip?: boolean;
  showNewInformationIndication?: boolean;
  icon: ReactElement;
  isLoadingUser: boolean;
  className?: string;
  closeMobileNav?: () => void;
  childEntries?: Array<{
    label: string;
    href: string;
    isNew?: boolean;
    notificationCount?: number;
  }>;
}

const animationProps = {
  initial: { height: 0 },
  animate: { height: 'auto' },
  exit: { height: 0 },
  transition: { duration: 0.15, ease: 'easeInOut' },
};

function SidebarLinkSection({
  label,
  mainHref,
  openSectionIfMatchesPrefix,
  showIncompleteAlert = false,
  showOnboardingRequiredTooltip = false,
  showSubmitRequiredTooltip = false,
  showNewInformationIndication = false,
  icon,
  isLoadingUser,
  className = '',
  closeMobileNav,
  childEntries,
}: SidebarLinkSectionProps): ReactElement {
  const router = useRouter();

  const pathnameStartsWithPrefix = router.pathname.startsWith(
    openSectionIfMatchesPrefix,
  );

  const [isActiveSection, setIsActiveSection] = useState(
    pathnameStartsWithPrefix,
  );

  const [isExpanded, setIsExpanded] = useState(
    childEntries && pathnameStartsWithPrefix,
  );

  return (
    <>
      <button
        type="button"
        onClick={(e) => {
          if (childEntries) {
            setIsExpanded(!isExpanded);
          } else {
            if (e.ctrlKey || e.metaKey) {
              window.open(mainHref, '_blank', 'noreferrer');
            } else {
              void router.push(mainHref);
              if (pathnameStartsWithPrefix) {
                setIsActiveSection(true);
              }
            }
            if (closeMobileNav) {
              closeMobileNav();
            }
          }
        }}
        className={`flex w-full flex-col rounded-lg p-3 text-lg font-medium text-leland-gray-dark hover:bg-leland-gray-hover active:bg-leland-gray-hover ${
          isActiveSection ? 'bg-leland-gray-hover' : ''
        } ${className}`}
      >
        <div className="flex w-full items-center justify-between text-left">
          <div className="flex items-center justify-start">
            <div className="relative flex items-center">{icon}</div>
            <span className="ml-4">{label}</span>
          </div>
          {!isLoadingUser ? (
            <div className="ml-2 flex items-center justify-center space-x-2">
              {showNewInformationIndication ? <NewIndicator /> : null}
              {showOnboardingRequiredTooltip ||
              showSubmitRequiredTooltip ||
              showIncompleteAlert ? (
                <IncompleteIndicator
                  className="size-5"
                  side={showSubmitRequiredTooltip ? 'bottom' : undefined}
                  onboarding={
                    showOnboardingRequiredTooltip || showSubmitRequiredTooltip
                  }
                  message={
                    showSubmitRequiredTooltip
                      ? 'Submit your profile here!'
                      : undefined
                  }
                />
              ) : null}
            </div>
          ) : null}
          {childEntries ? (
            isExpanded ? (
              <IconChevronUp className="size-4" />
            ) : (
              <IconChevronDown className="size-4" />
            )
          ) : null}
        </div>
      </button>
      {childEntries ? (
        <AnimatePresence initial={false}>
          {isExpanded ? (
            <motion.div className="overflow-hidden pl-6" {...animationProps}>
              <div className="flex flex-col items-stretch gap-1 border-l pl-3 font-normal">
                {childEntries.map((entry) => {
                  const isActive = router.asPath === entry.href;

                  return (
                    <Link
                      href={entry.href}
                      className={`flex items-center rounded-lg p-3 ${
                        isActive
                          ? 'bg-leland-gray-hover font-medium text-leland-gray-dark'
                          : 'text-leland-gray-light'
                      } hover:bg-leland-gray-hover hover:text-leland-gray-dark`}
                      key={entry.href}
                    >
                      {entry.label}
                      {entry.isNew ? <NewIndicator /> : null}
                      {entry.notificationCount != undefined &&
                      entry.notificationCount > 0 ? (
                        <NotificationIcon
                          isInbox={false}
                          icon=""
                          notificationCount={entry.notificationCount}
                        />
                      ) : null}
                    </Link>
                  );
                })}
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      ) : null}
    </>
  );
}

export default MainNavigationSidebar;
