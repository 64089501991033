// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MainNavigationPublicOpportunitiesQueryVariables = Types.Exact<{
  categoryUrns?: Types.InputMaybe<
    | Array<Types.Scalars['CategoryUrn']['input']>
    | Types.Scalars['CategoryUrn']['input']
  >;
  tiers: Array<Types.SkuTier> | Types.SkuTier;
  type?: Types.InputMaybe<Types.OpportunityType>;
}>;

export type MainNavigationPublicOpportunitiesQuery = {
  __typename?: 'Query';
  publicOpportunities: {
    __typename?: 'MatchedCoachBatchResponse';
    total: number;
  };
};

export type MainNavigationUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type MainNavigationUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coach?: { __typename?: 'Coach'; id: string; tier: Types.SkuTier } | null;
  } | null;
};

export const MainNavigationPublicOpportunitiesDocument = gql`
  query MainNavigation_PublicOpportunities(
    $categoryUrns: [CategoryUrn!]
    $tiers: [SkuTier!]!
    $type: OpportunityType
  ) {
    publicOpportunities(
      categoryUrns: $categoryUrns
      tiers: $tiers
      type: $type
    ) {
      total
    }
  }
`;

/**
 * __useMainNavigationPublicOpportunitiesQuery__
 *
 * To run a query within a React component, call `useMainNavigationPublicOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainNavigationPublicOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainNavigationPublicOpportunitiesQuery({
 *   variables: {
 *      categoryUrns: // value for 'categoryUrns'
 *      tiers: // value for 'tiers'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMainNavigationPublicOpportunitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MainNavigationPublicOpportunitiesQuery,
    MainNavigationPublicOpportunitiesQueryVariables
  > &
    (
      | {
          variables: MainNavigationPublicOpportunitiesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MainNavigationPublicOpportunitiesQuery,
    MainNavigationPublicOpportunitiesQueryVariables
  >(MainNavigationPublicOpportunitiesDocument, options);
}
export function useMainNavigationPublicOpportunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MainNavigationPublicOpportunitiesQuery,
    MainNavigationPublicOpportunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MainNavigationPublicOpportunitiesQuery,
    MainNavigationPublicOpportunitiesQueryVariables
  >(MainNavigationPublicOpportunitiesDocument, options);
}
export function useMainNavigationPublicOpportunitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MainNavigationPublicOpportunitiesQuery,
    MainNavigationPublicOpportunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MainNavigationPublicOpportunitiesQuery,
    MainNavigationPublicOpportunitiesQueryVariables
  >(MainNavigationPublicOpportunitiesDocument, options);
}
export type MainNavigationPublicOpportunitiesQueryHookResult = ReturnType<
  typeof useMainNavigationPublicOpportunitiesQuery
>;
export type MainNavigationPublicOpportunitiesLazyQueryHookResult = ReturnType<
  typeof useMainNavigationPublicOpportunitiesLazyQuery
>;
export type MainNavigationPublicOpportunitiesSuspenseQueryHookResult =
  ReturnType<typeof useMainNavigationPublicOpportunitiesSuspenseQuery>;
export type MainNavigationPublicOpportunitiesQueryResult = Apollo.QueryResult<
  MainNavigationPublicOpportunitiesQuery,
  MainNavigationPublicOpportunitiesQueryVariables
>;
export const MainNavigationUserDocument = gql`
  query MainNavigation_User {
    user {
      id
      coach {
        id
        tier
      }
    }
  }
`;

/**
 * __useMainNavigationUserQuery__
 *
 * To run a query within a React component, call `useMainNavigationUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainNavigationUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainNavigationUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainNavigationUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MainNavigationUserQuery,
    MainNavigationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MainNavigationUserQuery,
    MainNavigationUserQueryVariables
  >(MainNavigationUserDocument, options);
}
export function useMainNavigationUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MainNavigationUserQuery,
    MainNavigationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MainNavigationUserQuery,
    MainNavigationUserQueryVariables
  >(MainNavigationUserDocument, options);
}
export function useMainNavigationUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MainNavigationUserQuery,
    MainNavigationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MainNavigationUserQuery,
    MainNavigationUserQueryVariables
  >(MainNavigationUserDocument, options);
}
export type MainNavigationUserQueryHookResult = ReturnType<
  typeof useMainNavigationUserQuery
>;
export type MainNavigationUserLazyQueryHookResult = ReturnType<
  typeof useMainNavigationUserLazyQuery
>;
export type MainNavigationUserSuspenseQueryHookResult = ReturnType<
  typeof useMainNavigationUserSuspenseQuery
>;
export type MainNavigationUserQueryResult = Apollo.QueryResult<
  MainNavigationUserQuery,
  MainNavigationUserQueryVariables
>;
